.contact {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-top: 120px;
  margin-right: 80px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 20px;
}

.contactHeader {
  margin-top: 40px;
  font-size: 36px;
}

.section1 {
  display: flex;
}

.formSection {
  margin: 10px;
}

.sendButton {
  background-color: #ff5f15;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.sendButtonIcon {
  margin-left: 10px;
  font-size: 20px;
}

@media (max-width: 970px) {
  .contact {
    align-content: flex-start;
    margin-left: 60px;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .contact {
    align-content: flex-start;
    margin-left: 10px;
    margin-top: 60px;
    margin-right: auto;
  }

  .sendButtons {
    display: flex;
    justify-content: center;
    margin-left: -40px;
    margin-bottom: 60px;
  }
}
