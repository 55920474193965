.services {
  background-color: #f5f5f5;
  padding-top: 50px;
  margin-top: 80px;
  padding-bottom: 10px;
}

.servicesHeader {
  font-size: 36px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.serviceCardSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 50px;
}

.servicesCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 400px;
  height: 250px;
  margin: 0px;
}
.servicesCard:hover {
  border: 1px solid #d9d9d9;
  box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.servicesCardHeader {
  display: flex;
  align-items: center;
}
.serviceCardIcon {
  margin-right: 10px;
  font-size: 40px;
  color: #585858;
}

.servicesCardIcon,
.serviceCardTitle {
  font-size: 28px;
  font-weight: 600;
  display: inline-block;
  margin: 20px;
}

.serviceCardDesc {
  color: #585858;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 20px;
  text-align: justify;
}
