.portfolio {
  display: flex;
  flex-direction: column;
}

.portfolioHeader {
  display: flex;
  align-self: center;
  font-size: 36px;
  margin-top: 120px;
  margin-bottom: 80px;
}

.portfolioCardSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.portfolioCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.portfolioCard {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}

.portfolioCardImage {
  margin: 0px;
  max-width: 400px;
  height: auto;
  width: 100%;
  max-height: 205px;
}

.portfolioCardTiltleSection {
  display: flex;
  align-items: center;
  color: #000;
}

.portfolioCardTitle {
  font-size: 28px;
  margin-left: 20px;
  margin-right: auto;
}
.portfolioCardIcons {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 24px;
  margin-right: 20px;
}
.portfolioCardDesc {
  font-size: 16px;
  color: #585858;
  display: flex;
  flex-grow: 1;
  margin: 20px;
  text-align: justify;
}

.arrowIcon {
  color: #000;
  border-radius: 50%;
  padding: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.arrowIcon:hover {
  cursor: pointer;
}

@media (max-width: 923px) {
  .arrowIcon {
    display: none;
  }
}
