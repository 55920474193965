.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 120px;
  margin-left: 60px;
  margin-bottom: 50px;
}

.reviewsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: auto;
}

.reviewSectionDecor {
  font-size: 65px;
  color: #ff5f15;
  margin: 0px;
}

.reviewerName {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.reviewerCategory {
  font-size: 14px;
  margin: 0px;
  color: #ff5f15;
}

.reviewerDesc {
  width: auto;
  text-align: center;
  max-width: 450px;
  font-size: 16px;
  color: #585858;
  margin-top: 50px;
  text-align: justify;
}

.scrollReviewIcons {
  margin-bottom: 20px;
}

.scrollReviewIcon {
  margin: 20px;
  cursor: pointer;
}

.testimonialsSection {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.testimonialsCount {
  font-size: 32px;
  margin-right: 10px;
}

.testimonialsSubText {
  font-size: 16px;
  color: #585858;
  width: auto;
  max-width: 100px;
  margin-right: 10px;
}

.leaveReviewButton {
  background-color: #ff5f15;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  border: none;
}

@media (max-width: 970px) {
  .testimonials {
    align-self: center;
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .testimonials {
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .testimonialsSection {
    flex-direction: column;
    margin: auto;
  }
  .testimonialsCount {
    margin-bottom: 0px;
  }
  .reviewerDesc {
    margin: 15px;
  }
}
