.mainContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headerText {
  margin-top: 80px;
  font-size: 50px;
  font-weight: 600;
  margin-left: 80px;
  letter-spacing: 0px;
}

.subHeader {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.subHeader1 {
  color: #ff5f15;
}

.navbarStyle {
  display: flex;
  justify-content: flex-end;
}

.ctaButton {
  background-color: #ff5f15;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  margin-left: 80px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.ctaButton:hover {
  cursor: pointer;
  background-color: #fa7c41;
  color: #000;
}

.socialLinks {
  margin-left: 80px;
}

.socialLink {
  color: #000;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}

.skillsSphere {
  position: absolute;
  top: 120px;
  right: 80px;
  left: auto;
  margin: 0 auto;
}
.testimonialsAndContacts {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 970px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
  }
  .skillsSphere {
    z-index: -1;
    display: flex;
    justify-content: center;
    align-self: center;
    right: auto;
    left: auto;
    opacity: 50%;
  }

  .contentContainer {
    z-index: 1;
  }

  .testimonialsAndContacts {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .mainContainer {
    flex-direction: column;
  }

  .headerText {
    margin-left: 30px;
  }

  .ctaButton {
    margin-left: 30px;
  }

  .socialLinks {
    margin-left: 30px;
  }
}
