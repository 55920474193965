.navbar {
  padding: 10px 20px;
}
.navbar-menu {
  list-style: none;
  display: flex;
}
.navbar-menu li {
  margin-left: 50px;
  margin-right: 20px;
  cursor: pointer;
}

.navbar-menu a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
}

.navbar-menu a:hover {
  color: #ff5f15;
}
.navbar-toggle {
  display: none;
}
@media (max-width: 970px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #3498db;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    margin: 0;
    padding: 10px;
    text-align: center;
  }

  .navbar-toggle {
    display: block;
    font-size: 20px;
    cursor: pointer;
  }
}
